<template>
  <b-row :class="innerWidth < 700 ? 'mb-0': 'mb-2'">
    <b-col
      :cols="innerWidth < 1300 ? 12 : 3"
      align="left"
    >
      <div class="container-fluid">
        <d-date-time-picker
          identifier="startingDate"
          :model="booking.startAt"
          :date-time="booking.startAt"
          :show="showStartingDateTime"
          @date-time:update="onStartDateUpdate"
        />
        <d-date-time-picker
          :model="booking.endAt"
          :date-time="booking.endAt"
          :show="showEndingDateTime"
          :min-date-time="booking.startAt"
          identifier="endingDate"
          @date-time:update="onEndDateUpdate"
        />
        <b-row>
          <b-col
            class="hovered-container"
            align="middle"
          >
            <div class="flex-display-horizontal-center">
              <div>
                <div class="date-label">
                  {{ formatDate(booking.startAt) }}
                </div>
                <div :class="innerWidth < 700 ? 'time-label-mobile' : 'time-label'">
                  {{ formatTime(booking.startAt) }}
                </div>
              </div>
              <div class="flex-display-vertical-center">
                  <span
                    @click="showStartingDateTime = !showStartingDateTime"
                    class="pointer participant-edit-icon icofont icofont-ui-edit"
                  ></span>
              </div>
            </div>
          </b-col>
          <div class="split-layout__divider">
            <div class="split-layout__rule"></div>
            <div class="split-layout__rule"></div>
          </div>
          <b-col
            class="hovered-container"
            align="middle"
          >
            <div class="flex-display-horizontal-center">
              <div>
                <div class="date-label">
                  {{ formatDate(booking.endAt) }}
                </div>
                <div :class="innerWidth < 700 ? 'time-label-mobile' : 'time-label'">
                  {{ formatTime(booking.endAt) }}
                </div>
              </div>
              <div class="flex-display-vertical-center">
                   <span
                     @click="showEndingDateTime = !showEndingDateTime"
                     class="pointer participant-edit-icon icofont icofont-ui-edit"
                   ></span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col
      class="participant-right-label"
      :align="innerWidth < 991 ? 'center' : 'right'"
    >
      <div
        :class="innerWidth < 991 ? 'booking-label' : 'pr-4 mr-3 time-label'"
        class="hovered-container "
      >
        <div
          v-if="!showInputLine"
          class="d-inline-block"
        >
          {{booking.name === null || booking.name.trim().length === 0 ? bookingName : booking.name }}
          <span
            class="pointer participant-edit-icon icofont icofont-ui-edit"
            @click="showInputLine = !showInputLine; name = booking.name"
          />
        </div>
        <div
          v-else
          class="d-inline-block"
        >
          <input-line
            v-model="name"
          />
          <span
            class="pointer participant-check-icon icofont icofont-check mr-1"
            @click="updateBookingName()"
          />
          <span
            class="pointer participant-close-icon icofont icofont-close"
            @click="showInputLine = !showInputLine"
          />
          <b-form-invalid-feedback
            :state="name.trim().length > 3"
          >
            {{ $t('validation.text-input-three-characters') }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import Booking from "@/classes/doinsport/Booking";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import InputLine from "@custom/booking/InputLine";
import {FORMULA_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import {FORMULA_TYPE} from "@/classes/doinsport/Price";

const moment = require('moment');

export default {
  components: {InputLine},
  props: {
    booking: {
      type: Object,
      default: () => new Booking()
    }
  },
  data: () => ({
    showStartingDateTime: false,
    showEndingDateTime: false,
    showInputLine: false,
    name: ''
  }),
  computed: {
    bookingName () {
      const blockPrice = this.$store.getters["playgrounds/getSelectedBlockPrices"].find(el => el['@id'] === this.booking.timetableBlockPrice);

      if (isNotUndefinedAndNotNull(blockPrice)) {
        return blockPrice.activityType === FORMULA_TYPE ? this.$t('components.custom.planning.booking-details-modal.general-information.formula-name') : this.$t('components.custom.planning.booking-details-modal.general-information.name');
      }

      return this.$t('components.custom.planning.booking-details-modal.general-information.name');
    },
    innerWidth() {
      return this.$store.getters["layout/getInnerWidth"];
    },
  },
  methods: {
    onStartDateUpdate(date) {
      this.booking.startAt = date;
      this.$bus.$emit('on:date-time-pickers:update');

      if (this.$moment(this.booking.startAt).isAfter(this.$moment(this.booking.endAt))) {
        const blockPrice = this.$store.getters["playgrounds/getSelectedBlockPrices"].find(el => el['@id'] === this.booking.timetableBlockPrice);

        if (isNotUndefinedAndNotNull(blockPrice)) {
          this.booking.endAt = this.$moment(date).add(blockPrice.duration / 60, 'minutes');
        } else {
          this.booking.endAt = date;
        }
      }
    },
    updateBookingName() {
      if (this.name.trim().length > 3) {
        this.booking.name = this.name;
        this.booking.nameManuallyUpdated = true;
        this.showInputLine = !this.showInputLine;
      }
    },
    onEndDateUpdate(date) {
      this.booking.endAt = date;
      this.$bus.$emit('on:date-time-pickers:update');
    },
    formatDate(date) {
      return this.$moment(date).format('DD/MM/YYYY');
    },
    formatTime(date) {
      return this.$moment(date).format('HH:mm');
    },
    displayInputLine() {
      this.showInputLine = !this.showInputLine
    }
  },
  mounted () {
    this.$bus.$on('on:empty-booking-name', this.displayInputLine);
  },
  beforeDestroy() {
    try {
     this.$bus.$off('on:empty-booking-name', this.displayInputLine);
    } catch (e) {}
  }
}
</script>

<style scoped lang="scss">
@import "@lazy/bookings/_booking-header.scss";

.invalid-feedback {
  text-align: left;
  display: none;
  width: 100%;
  font: normal normal 900 13px Avenir;
  color: #dc3545;
}
</style>
